import { getInitDataSer, getInitLanguageSer } from '@user/login/service/fetch.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
const { SiteUID } = gbCommonInfo

export function getLoginPageLanguage(cb, isRefresh = false ) {
  if (
    typeof window.LoginPageLangText !== 'undefined' &&
      Object.keys(window.LoginPageLangText).length && !isRefresh
  ) {
    cb && cb({ language: window.LoginPageLangText })
    return
  }
 
  getInitDataSer()
    .then((res) => {
      const { multiTips, abtInfo, preferOptions, newUserRights, historyAccountList, ipLocationInfo, subSiteInfo } = res?.info || {}
      window.LoginPageLangText = multiTips
      if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_abt = abtInfo
      if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_data = {
        configData: {
          webSubSites: subSiteInfo?.webSubSites?.includes(SiteUID) || false,
          webNotSubSites: subSiteInfo?.webNotSubSites?.includes(SiteUID) || false
        },
        preferOptions,
        cccNewUserRigths: { data: newUserRights },
        historyAccountList,
        ipLocationInfo,
        ATO_SDK_SWITCH: gbCommonInfo.ATO_SDK_SWITCH,
        isSSRLogin: UserInfoManager.isLogin(),
        uid: UserInfoManager.get({ key: 'memberId', actionType: 'initDialogPreloadData' }) || '',
      }
      cb && cb({ language: multiTips })
    })
    .catch(() => {
      getInitLanguageSer()
        .then((res) => {
          const language = res?.info?.result || {}
          window.LoginPageLangText = language
          cb && cb({ language })
          return
        })
    })
}
