/**
 * 公共模块
 * 关于公共模块的使用说明:
 * 1. 只引入全业务都需要的功能或能力
 * 2. 不要引入仅某业务或某几个业务所需的能力从而导致公共模块过于体积及执行效率下降
 * 3. 全局挂载的全局变量或方法需谨慎考虑是否一定要挂到全局，如果不是必须的，则回归业务本身, 如 SHEIN_W
 */
import { getQueryString, windowCacheDeleteCb } from '@shein/common-function'
import { _sCommonController } from '@shein/common-function'
const { PUBLIC_CDN, LAYOUT } = gbCommonInfo
__webpack_public_path__ = `${PUBLIC_CDN}/she_dist/assets/`

//需要比_sCommonController.registLocals先执行
import './globalCheck'

_sCommonController.registLocals(gbCommonInfo)
import './polyfill'
import './sw'
import './shein_w/core'
import '../../services/TPM'
import './ugidHandle'
import '../../services/schttp/prefetchCsrf'
import { triggerPreFetchProductListPromise } from 'public/src/services/pre_requests/common'
triggerPreFetchProductListPromise()
import './userInfo'
import ADA from './ada'
typeof window !== 'undefined' && new ADA()
import './performance'
import '../../services/abt'
import './devices'
import './infp'
import './point_migrate' // 新站点重开站点积分迁移
import emarsys from '../../services/productRecommend/emarsys'
import './ip_address_report'

// emarsys 初始化提前
emarsys.init()
if (gbCommonInfo.headerVueSsr !== true) {
  if (gbCommonInfo.IS_NEW_HEAD && !gbCommonInfo.noHeader) {
    import(/* webpackChunkName: "CommonHeader" */ './common-header').then(({ default: initCommonHeader }) => { initCommonHeader() })
  } else {
    import(/* webpackChunkName: "header" */ './header').then(({ default: initHeader }) => { initHeader() })
  }
}
if (gbCommonInfo.IS_NEW_HEAD) {
  gbCommonInfo.NEW_COMMON_FOOTER ? 
  import(/* webpackChunkName: "CommonFooter" */ './common-footer').then(({ default: initCommonFooter }) => { initCommonFooter() }) :
  import(/* webpackChunkName: "CommonFooterOld" */ './common-footer-old').then(({ default: initCommonFooter }) => { initCommonFooter() })
}

import './setStatDynamic'
import '../components/globalPrivacy/asyncIndex'
import './fs_image'
import '../components/login/asyncIndex'
import '../components/signup_fast/asyncIndex'
import { initOneTrusPathDisplay } from './onetrust'
import { initPrivacyPopDisplay } from './privacyPop'
// Webp 大盘的支持情况, 已收集完毕, 不再重复收集
// import './deviceFingerprint/send'
import './tips_mask/loadTipsMark'
// 业务逻辑
import './mdmpUserLanding'
import '../components/publicBanner/diverter'
import './analysis'
import './default_currency'
import './init'
import '../components/sidebar/loadSideBar'
import './monitor'
import { loadUserDropDownContainer } from 'public/src/pages/common/userDropdown/loadUserDropDown.js'
import { loadGlobalPopModule } from './glob_popup_modal'
import './abtPageAll'
import { istanbulAnalytics } from './istanbul-analytics'
import { isLogin, onWindowLoad } from 'public/src/pages/common/utils/index.js'
onWindowLoad(() => {
  loadUserDropDownContainer()
  requestAnimationFrame(function () {
    if (LAYOUT === 'layout') {
      if (!gbCommonInfo.IS_NEW_HEAD) {
        import(/* webpackChunkName: "search-words-module" */ './search_words')
      }
      import(
        /* webpackChunkName: "pre-online-help-module" */ './pre_online_help'
      )
      // 任务中心浏览倒计时 & 加车进度条新样式
      if (getQueryString({ key: 'actTaskType' })) {
        const actTaskType = getQueryString({ key: 'actTaskType' })
        if (actTaskType == 'browse') {
          // 浏览倒计时
          if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
            window.gbTaskCenterBrowseTips.init()
          } else {
            import(
              /* webpackChunkName: "TaskCenterBrowse" */ './task-center-tips/browse'
            )
          }
        } else if (actTaskType == 'addCart') {
          // 加车进度条
          if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
            window.gbTaskCenterAddCartTips.init()
          } else {
            import(
              /* webpackChunkName: "TaskCenterAddCart" */ './task-center-tips/add-cart'
            )
          }
        }
      } else {
        if (typeof window.gbTaskCenterBrowseTips !== 'undefined') {
          window.gbTaskCenterBrowseTips.show = false
          if (window.gbTaskCenterBrowseTips.timer)
            clearInterval(window.gbTaskCenterBrowseTips.timer)
        }
        if (typeof window.gbTaskCenterAddCartTips !== 'undefined') {
          window.gbTaskCenterAddCartTips.show = false
        }
      }
    }


    if (LAYOUT === 'layout' && !gbCommonInfo.IS_NEW_HEAD) {
      import(/* webpackChunkName: "cart-module-mini" */ './cart_module_mini').then(
        () => {}
      )
    }
    // 全局弹窗控制入口
    loadGlobalPopModule()

    // 记录访问历史
    void (function visitTrajectory() {
      // trajectory
      if (window.sessionStorage) {
        const sessionVisitTrajectory =
          window.sessionStorage.getItem('visit_trajectory')
        const deep = 5 // 记录深度

        let visit_trajectory = sessionVisitTrajectory
          ? JSON.parse(sessionVisitTrajectory)
          : []
        visit_trajectory.push(window.SaPageInfo?.page_name || '')

        if (visit_trajectory.length > deep) {
          visit_trajectory = visit_trajectory.slice(
            -deep,
            visit_trajectory.length
          )
        }
        window.sessionStorage.setItem(
          'visit_trajectory',
          JSON.stringify(visit_trajectory)
        )
      }
    })()
  })
  window.dispatchEvent(new Event('scroll'))

  // 展示隐私弹窗sdk逻辑
  initPrivacyPopDisplay()

  // 展示onetrust逻辑
  initOneTrusPathDisplay()

  !isLogin() && import('./googleOneTab/index')
})

windowCacheDeleteCb({
  keys: ['cartDynamicData', 'campaignsDynamicData']
})

onWindowLoad(() => {
  requestIdleCallback(() => {
    window.TPM?.publish('pageview', {
      page_name: window.SaPageInfo?.page_name || '',
    })
    if (['gray', 'debug'].includes(gbCommonInfo.NODE_SERVER_ENV)) {
      if(navigator.webdriver) return
      setTimeout(() => {
        istanbulAnalytics()
      }, 1000)
      setInterval(() => {
        istanbulAnalytics()
      }, 1000 * 60 * 5)
    }
  })

  // 不支持 Proxy 语法的进行埋点上报，用于统计我们站点 Vue 3 访问情况
  if (!window.Proxy) {
    window.sa('send', {
      activity_name: 'expose_browser_not_support_proxy',
    })
  }

  isLogin() && import('./bindMessenger.js')
})
