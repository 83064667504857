import { windowCacheDeleteCb } from '@shein/common-function'
import { onWindowLoad } from 'public/src/pages/common/utils/index.js'
import { abtservice } from 'public/src/services/abt'
import { isSupported } from 'firebase/messaging'

const FIREBASESWPATH = '/firebase-messaging-sw.js'

async function registerSW(swFile){
  const { langPath } = gbCommonInfo
  try {
    const registration = await navigator.serviceWorker.register(swFile)
    await registration.update()
    if(swFile === FIREBASESWPATH){
      window.firebaseSWLoaded = true
    }
    window.dispatchEvent(new Event('SW_Loaded'))
    window.SW_Loaded = true
  } catch (error) {
    if(error && error.toString().includes('FirebaseError')){
      console.error('FireBaseSW 注册失败,正在重新注册sw-runtime-cache', error)
      registerSW(langPath + '/sw-runtime-cache.js')
    }else{
      console.error('ServiceWorker 注册失败。', error)
    }
  }
}

if ('serviceWorker' in navigator && typeof fetch !== 'undefined') {
  onWindowLoad(() => {
    requestUserIdle(async () => {
      const { langPath } = gbCommonInfo
      // 通过abt控制当前sheinPush是否生效
      // eslint-disable-next-line @shein-aidc/abt/abt
      const promises = [
        abtservice.getUserAbtResult({
          newPosKeys: 'webpushsubscribe'
        }),
        isSupported()
      ]
      const [ abtInfo, isSupportPushAPI ] = await Promise.all(promises)
      const isOpenSheinPush = abtInfo?.webpushsubscribe.param?.webpush_subscribe === 'on'
      let swFile
      if(isSupportPushAPI && isOpenSheinPush){
        swFile = FIREBASESWPATH
      }else{
        swFile = langPath + '/sw-runtime-cache.js'
      }
      registerSW(swFile)
    })
  })
}

// 页面访问时清除sw缓存
(() => {
    const swCacheClearConfig = {
      '/user/auth/login': ['pcMeHtml']
    }

    Object.keys(swCacheClearConfig).forEach((key) => {
      if(location.pathname.includes(key)) {
        windowCacheDeleteCb({ key: swCacheClearConfig[key] })
      }
    })
})()

onWindowLoad(() =>
  requestIdleCallback(async () => {
    try {
      if (typeof caches !== 'undefined') {
        const inuseNamespace = [
          'addressbook',
          'addressbook-post',
          'campaignsDynamicData',
          'cartDynamicData',
          'dynamicData',
          'homePageDynamicData',
          'payDynamicData',
          'pcCheckoutHtml',
          'pcMeHtml',
        ]
        const keys = await caches.keys()
        await Promise.all(keys.filter(_ => !inuseNamespace.includes(_)).map(useLessKey => caches.delete(useLessKey)))
      }
    } catch (error) {
      console.error(error)
    }

    try {
      if ('serviceWorker' in navigator) {
        const workers = await navigator.serviceWorker.getRegistrations()
        await Promise.all(workers.map(worker =>
          /sw-runtime-cache-pay-dest|aimtell-worker/.test(worker.active?.scriptURL)
            ? worker.unregister()
            : false
        ))
      }
    } catch (error) {
      console.error(error)
    }
  })
)
