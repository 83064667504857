import {
  patternEmail,
} from '@shein/common-function'


export const passwordPattren = /^(?=.*\d)(?=.*[a-zA-Z])[^\s]{8,}$/

// 检测邮箱合法性
export function checkEmail(email, tip) {
  const langText = window.LoginPageLangText || {}
  if (!email.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15165
    return false
  }
  if (!patternEmail({ email })) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15690
    return false
  }
  tip.show = false
  return true
}

// 检测密码合法性
export function checkPw(pw, tip, type) {
  const langText = window.LoginPageLangText || {}
  if (!pw.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15694
    return false
  }
  if (pw.length < 6) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15692
    return false
  }
  if (type === 'sign-up' && !passwordPattren.test(pw)) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_14477
    return false
  }
  tip.show = false
  tip.txt = ''
  return true
}

export function formatErrorLoginInput(email, password, noPassword = false) {
  if (email === '') return 'empty_email'
  if (!patternEmail({ email })) return 'incorrect_email_format'
  if (noPassword) return
  if (password === '') return 'empty_password'
  if (password.length < 6) return 'password_less_than_6_characters'
  return 'other'
}

export function formatErrorLoginInputGa(email, password) {
  if (email === '') return 'Please fill in the mailbox'
  if (!patternEmail({ email })) return 'Mailbox validation failure'
  if (password === '') return 'Please enter the password'
  if (password.length < 6) return 'Password format is incorrect'
  return 'other'
}

export function formatErrorSignUpInput(email, password, cfpassword) {
  if (email === '') return 'empty_email'
  if (!patternEmail({ email, signUp: true })) return 'incorrect_email_format'
  if (password === '') return 'empty_password'
  if (!passwordPattren.test(password)) return 'invalid_password'
  if (password != cfpassword) return 'confirm_password_inconsis'
  return 'other'
}

export function formatErrorSignUpInputGa(email, password, cfpassword) {
  if (email === '') return 'Please fill in the mailbox'
  if (!patternEmail({ email, signUp: true }))
    return 'Mailbox validation failure'
  if (password === '') return 'Please enter the password'
  if (!passwordPattren.test(password)) return 'Password format is incorrect'
  if (password != cfpassword)
    return 'The password and the confirmation you typed do not match'
  return 'other'
}

// 格式化手机号码常规前端验证错误
export function formatErrorPhoneSa(code, password, mode) {
  if (mode == 'code') {
    if (code == '') return 'empty_verification_code'
  }
  if (mode == 'password') {
    if (password == '') return 'empty_password'
    if (password.length < 6) return 'invalid_password'
  }
  return 'other'
}

export const LoginPatterRegex =
  /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/

// 检测注册邮箱合法性
export function checkSignUpEmail(email, tip) {
  const langText = window.LoginPageLangText || {}
  if (!email.length) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15689
    return false
  }
  if (!patternEmail({ email, signUp: true })) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15695
    return false
  }
  tip.show = false
  return true
}

export function checkConfirmPassord(password, cfpassword, tip) {
  const langText = window.LoginPageLangText || {}
  if (password != cfpassword) {
    tip.show = true
    tip.txt = langText.SHEIN_KEY_PC_15693
    return false
  }
  return true
}

function sortPhoneArea(list) {
  if (list.length == 0) return
  list.sort((v1, v2) => {
    let name1 = v1?.name || ''
    let name2 = v2?.name || ''

    if (!name1 || !name2) return 0
    const len = name1.length

    let flag = 0

    for (let i = 0; i < len; i++) {
      const ch1 = name1.charAt(i)
      const ch2 = name2.charAt(i)

      if (ch1 > ch2) {
        flag = 1
        break
      }
      if (ch1 < ch2) {
        flag = -1
        break
      }
    }
    return flag
  })
}

export function formatSupportsPhoneAreaCodes(data) {
  const list = []
  const { PHONE_SUPPORT_COUNTRYS } = gbCommonInfo
  PHONE_SUPPORT_COUNTRYS.forEach((k) => {
    const v = data.find((v1) => v1.value == k)
    if (v) {
      list.push({
        code: v.phone_code.replace('+', ''),
        abbr: v.value,
        value: v.value,
        name: v.country,
        siteUid: v.siteUid || '',
      })
    }
  })
  sortPhoneArea(list)
  return list
}

export function formatAllPhoneAreaCodes(data) {
  const list = []
  data.forEach((item) => {
    list.push({
      code: item.phone_code?.replace('+', ''),
      abbr: item.value,
      name: item.country,
    })
  })
  return list
}
